import { LoginRequest } from '@solin-fitness/types';
import { fetchLogin } from 'queries/auth';
import { useMutation } from 'react-query';

const useLogin = () => {
  return useMutation((data: LoginRequest) =>
    fetchLogin(data.email, data.password),
  );
};

export default useLogin;
